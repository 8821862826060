export const data = {
  user: {
    userId: 5,
    username: "motor_",
    avatar: "",
    tourNumber: 12,
    top3AllTimeGames: ["Rocket League", "Need for Speed: Most Wanted (2007)", "Grid"],
    top3IndieGames: ["Armello", "The Flame in the Flood", "Don't Starve"],
    favoriteGenres: ["RPG", "Racing", "Competitive", "Board Games"],
    favoriteSettings: ["Fantasy", "Dark"],
  },
  games: [
    {
      gameId: 1,
      gameName: "Redout: Enhanced Edition",
      score: 86,
      ratings: 2453,
      gameHeader: "redout.jpg",
      developer: "34BigThings",
      shortDescription: "Redout is a tribute to the old racing monsters such as F-Zero, WipeOut, Rollcage, and POD. It is designed to be an uncompromising, fast, tough and satisfying driving experience, soaked in that vertigo that stands at the core of the arcade racing genre. ",
      gameChip: ["Futuristic Racing"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/517710/Redout_Enhanced_Edition/",
      },
      reasonsToPlay: ["Great Soundtrack", "Sci-Fi", "Similar to your all-time favorites"],
      awards: [""],
    },
    {
      gameId: 2,
      gameName: "WARTILE",
      score: 78,
      ratings: 389,
      gameHeader: "wartile.jpg",
      developer: "Playwood Project",
      shortDescription: "Wartile is a cool-down based strategy game in which you control a warband of Viking figurines in a miniature universe inspired by Norse mythology. Unravel its secrets and rein the powers of the gods. ",
      gameChip: ["Similar to Armello"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/404200/WARTILE/",
      },
      reasonsToPlay: ["Vikings", "Board Game", "RPG"],
      awards: [""],
    },
    {
      gameId: 3,
      gameName: "Gremlins, Inc.",
      score: 86,
      ratings: 3222,
      gameHeader: "gremlins-inc.jpg",
      developer: "Charlie Oscar",
      shortDescription: "This is an intense strategy board game in a steampunk world of corrupt capitalist gremlins who compete for money, political power and prestige. Save and invest, steal and extort, arrest and get arrested – in single-player and multiplayer, with ranks and ladders, team mode and spectator mode. ",
      gameChip: ["Indie"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/369990/Gremlins_Inc/",
      },
      reasonsToPlay: ["Board Game", "Strategy"],
      awards: [""],
    },
    {
      gameId: 4,
      gameName: "BELOW",
      score: 71,
      ratings: 540,
      gameHeader: "below.jpg",
      developer: "Capybara Games",
      shortDescription: "BELOW is a solitary journey through the haunted depths of a forbidden isle. Explore. Survive. Discover.",
      gameChip: ["Indie"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/250680/BELOW/",
      },
      reasonsToPlay: ["Similar to The Flame in the Flood", "Atmospheric", "RPG"],
      awards: [""],
    },
    {
      gameId: 5,
      gameName: "7 Days to Die",
      score: 78,
      ratings: 58745,
      gameHeader: "7-days-to-die.jpg",
      developer: "The Fun Pimps",
      shortDescription: "7 Days to Die is an open-world game that is a unique combination of first person shooter, survival horror, tower defense, and role-playing games. Play the definitive zombie survival sandbox RPG that came first. Navezgane awaits!",
      gameChip: ["Early Access"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/251570/7_Days_to_Die/",
      },
      reasonsToPlay: ["Halfway between Don't Starve and Halo", "RPG", "Survival"],
      awards: [""],
    },
    {
      gameId: 6,
      gameName: "SUNLESS SEA",
      score: 83,
      ratings: 5264,
      gameHeader: "sunless-sea.jpg",
      developer: "Failbetter Games",
      shortDescription: "LOSE YOUR MIND. EAT YOUR CREW. DIE. Take the helm of your steamship and set sail for the unknown! Sunless Sea is a game of discovery, loneliness and frequent death, set in the award-winning Victorian Gothic universe of Fallen London.",
      gameChip: ["Indie Gem"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/304650/SUNLESS_SEA/",
      },
      reasonsToPlay: ["Similar to The Flame in the Flood", "Exploration", "Survival"],
      awards: [""],
    },
    {
      gameId: 8,
      gameName: "The Long Dark",
      score: 91,
      ratings: 47647,
      gameHeader: "the-long-dark.jpg",
      developer: "Hinterland Studio",
      shortDescription: "The Long Dark is a thoughtful, exploration-survival experience that challenges solo players to think for themselves as they explore an expansive frozen wilderness in the aftermath of a geomagnetic disaster. There are no zombies -- only you, the cold, and all the threats Mother Nature can muster. ",
      gameChip: ["Indie"],
      storeLinks: {
        steam: "https://store.steampowered.com/app/305620/The_Long_Dark/",
      },
      reasonsToPlay: ["Similar to Flame in the Flood & Don't Starve", "Survival"],
      awards: [""],
    },
  ],
  stats: {
    number: [1, 5, 2],
    category: ["Racing Game", "Indie Survival Games", "Board Games"],
    reason: ["because you like Rocket League & Need for Speed", "because you like Don't Starve & The Flame in the Flood", "because you like Armello"],
  },
};